<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="actual"
      :rules="formRules.actual"
      mode="lazy"
    >
      <v-text-field
        v-model="form.actual"
        flat
        clearable
        label="Contraseña actual"
        :type="showCurrentPassword ? 'text' : 'password'"
        :error-messages="errors"
        :append-icon="showCurrentPassword ? 'visibility' : 'visibility_off'"
        @click:append="showCurrentPassword = !showCurrentPassword"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="nueva"
      :rules="formRules.nueva"
    >
      <v-text-field
        v-model="form.nueva"
        flat
        clearable
        label="Contraseña nueva"
        :type="showNewPassword ? 'text' : 'password'"
        :error-messages="errors"
        :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
        @click:append="showNewPassword = !showNewPassword"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="repetir"
      :rules="formRules.repetir"
    >
      <v-text-field
        v-model="form.repetir"
        flat
        clearable
        label="Repetir contraseña"
        :type="showConfirmPassword ? 'text' : 'password'"
        :error-messages="errors"
        :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
        @click:append="showConfirmPassword = !showConfirmPassword"
      />
    </ValidationProvider>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './UsuarioFormData'
import  { extend } from 'vee-validate'
import { USUARIO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    usuarioIdusuario: {
      type: [Number, String],
      default: null
    },
    usuarioLogin: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        actual: '',
        nueva: '',
        repetir: '',
      },
      formRules: {
        actual: { required: true, correctPasword: true },
        nueva: { required: true, passwordLength: true },
        repetir: { required: true, contraseniasCoinciden: true },
      },
      showNewPassword: false,
      showConfirmPassword: false,
      showCurrentPassword: false,
      USUARIO: USUARIO,
    }
  },
  async created () {
    extend('contraseniasCoinciden', {
      message: 'La contraseña debe coincidir.',
      validate: async (value) => {
        return (value && value === this.form.nueva)
      }
    })
    extend('passwordLength', {
      message: `Debe contener al menos ${this.USUARIO.minPasswordLength} caracteres.`,
      validate: async (value) => {
        return (value && value.length >= this.USUARIO.minPasswordLength )
      }
    })
    extend('correctPasword', {
      message: 'La contraseña actual no es correcta.',
      validate: async (value) => {
        return await Data.contraseniaCorrecta(this, this.usuarioIdusuario, value)
      }
    })
    await this.loadForm()
  },
}
</script>
